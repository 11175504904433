import React, {useEffect, useCallback, useState} from "react";
import PropTypes from "prop-types";
import {useSelector, shallowEqual} from "react-redux";
import {FindEntity} from "../../../state/slices/entities";
import useHubContext from "../../../hooks/useHubContext";
import {useStyles} from "./ScreenView.style";
import {Box, Modal} from "@material-ui/core";
import CallCenterService from "../../../services/CallCenterService";
import apiClient from "../../../auth/apiClient";
import Image from 'material-ui-image';
import {CALL_CENTER_CAPTURE_UPLOADED} from "../../../constants";

const callCenterService = new CallCenterService(apiClient);

export default function ScreenView({className, deviceID}) {
  const classes = useStyles();
  const [currentScreenshot, setCurrentScreenShot] = useState();
  const [currentScreenshotTime, setCurrentScreenshotTime] = useState(null);
  const [open, setOpen] = useState(false);
  const {Connected: PortalHubConnected, portalHub} = useHubContext();

  const viewScreen = () => {
    portalHub.invoke("CallCenter", {
      action: "VIEW_SCREEN",
      callDetails: {
        deviceID,
        payload: {},
      },
    });
  }

  useEffect(() => {
    viewScreen();
  }, [PortalHubConnected]);

  useEffect(() => {
    portalHub.subscribe(`${deviceID}_${CALL_CENTER_CAPTURE_UPLOADED}`, (payload) => {
      setCurrentScreenshotTime(new Date())
    });

    return () => portalHub.unsubscribe(`${deviceID}_${CALL_CENTER_CAPTURE_UPLOADED}`);
  }, [deviceID]);

  useEffect(() => {
    //separated deviceID so it wipes out screen cap when switching calls
    setCurrentScreenShot("no-image");
  }, [deviceID]);

  useEffect(() => {
    fetchLatestScreenCapture(deviceID);
    viewScreen();
  }, [currentScreenshotTime, deviceID]);

  const fetchLatestScreenCapture = useCallback(
    async (deviceID) => {
      try {
        const response = await callCenterService.getLastScreenCapture(deviceID);
        var r = new FileReader();
        r.readAsDataURL(response.data);
        r.onload = () => {
          setCurrentScreenShot(r.result);
        };
      } catch (err) {
        console.error(err);
      }
    },
    [deviceID]
  );

  return (
    <>
      <Image
        className={classes.screenImage}
        alt="device-view"
        src={currentScreenshot}
        onClick={() => setOpen(true)}
        imageStyle={{height: 'inherit'}}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        onClick={() => setOpen(false)}
      >
        <Box className={classes.screenImageModalBox}>
          <img className={classes.modalImage} alt="device-view-large" src={currentScreenshot}/>
        </Box>
      </Modal>
    </>
  );
}

ScreenView.propTypes = {
  className: PropTypes.string,
  deviceID: PropTypes.string.isRequired,
};
