import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import { Button, Divider, Drawer, Typography } from "@material-ui/core";
import useDeviceRTC from "../../../hooks/useDeviceRTC";
import Skeleton from "@material-ui/lab/Skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import {
  faExclamationCircle,
  faImagePolaroid,
  faSpinnerThird,
  faWindowClose,
} from "@fortawesome/pro-duotone-svg-icons";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { faPhone, faPhoneSlash } from "@fortawesome/pro-regular-svg-icons";
import { useStyles } from "./VideoFeed.style";
import { FindEntity } from "../../../state/slices/entities";
import { CONNECTION_STATE } from "../../../constants";
import { Box } from "@material-ui/core";
import ScreenView from "./ScreenView";
import {
  Tooltip,
} from "@material-ui/core";
import { IconButton } from "@material-ui/core";

const VideoFeed = ({
  deviceID,
  height,
  width,
  onConnecting,
  onConnected,
  onDisconnected,
}) => {
  //entityID, height, width
  const classes = useStyles();
  
  const entity =
    FindEntity(
      useSelector((state) => state.entities.EntityList),
      deviceID,
      shallowEqual
    ) ?? {};
  const queueItem = useSelector((state) =>
    _.find(state.callcenter.Queue, { deviceID })
  );
  const currentUser = useSelector((state) => state.user);

  const [screenDrawerOpen, setScreenDrawerOpen] = React.useState(false);

  const [connectionState, setConnectionState] = React.useState({
    connecting: false,
    connected: false,
  });


  useEffect(() => {
    //main, populate list

    return () => {
      handleEndCall();
    };
  }, [deviceID]);

  const openDrawer = ()=>{setScreenDrawerOpen(true);}
  const closeDrawer = ()=>{setScreenDrawerOpen(false);}



  const handleEndCall = () => {
    console.log("videofeed: handleEndCall (calling endCall)");
    setConnectionState({ connecting: false, connected: false });
    endCall();
  };
  const handleStartCall = () => {
    if(queueItem.connected?.state == CONNECTION_STATE.CONNECTING) return;
    onConnecting();
    console.log("videofeed: handleStartCall (calling rtc:startCall)");
    setConnectionState({ connected: false, connecting: true });
    startCall();
  };
  //playing stream
  const handlePlaying = () => {
    console.log("videofeed:handlePlaying");
    setConnectionState({ connected: true, connecting: false });
    onConnected();
  };
  //connection is loading
  const handleLoading = () => {
    console.log("videofeed: handleLoading");
    setConnectionState({ connected: false, connecting: true });
  };
  //connection is made, not yet playing
  const handleonLoadedData = () => {
    console.log("videofeed: handleonLoadedData");
  };
  //stream/playing is stopped
  const handleOnSuspend = () => {
    console.log("videofeed: handleOnSuspend");
    setConnectionState({ connected: false, connecting: false });
    onDisconnected();
  };
  const { startCall, endCall, remoteMediaRef } = useDeviceRTC(deviceID, {
    audio: true,
    initiator: true,
  });

  return (
    <>
    <div className={clsx([classes.root, "video-wrapper"])}>
      <div
        className={clsx([classes.videoContainer])}
        style={{ width: width, height: height }}
      >
        <video
          id={deviceID}
          data-connected={connectionState.connected}
          className={clsx([
            classes.video,
            connectionState.connected ? "connected" : null,
          ])}
          onLoadStart={handleLoading}
          onLoadedData={handleonLoadedData}
          onPlaying={handlePlaying}
          onSuspend={handleOnSuspend}
          controls
          autoPlay
          ref={remoteMediaRef}
          playsInline
          style={{ height, width }}
        />
        <FontAwesomeIcon
          className={classes.videoWaiting}
          data-connected={connectionState.connected}
          icon={faCamera}
          size="8x"
        />
        {connectionState.connecting && (
          <div className={classes.connecting} style={{ height, width }}>
            <Skeleton
              animation="wave"
              variant="rect"
              width={width}
              height={height}
            />
          </div>
        )}
      </div>
      <div className={classes.videoActions} style={{ width }}>
        {entity.state === null && (
          <FontAwesomeIcon
            className={classes.notResponding}
            icon={faExclamationCircle}
            title="Device Not Responding"
          ></FontAwesomeIcon>
        )}
        <Button
          data-connected={connectionState.connected}
          data-connection-id={deviceID}
          className={clsx([
            "video-feed-button",
            "animate__animated",
            "animate__flipInY",
          ])}
          variant="outlined"
          size="small"
          label={entity.state === null ? "Device Not Responding" : ""}
          color={connectionState.connected ? "secondary" : "primary"}
          disabled={
            queueItem.connected?.state == CONNECTION_STATE.CONNECTING ||
            (queueItem.connected?.state == CONNECTION_STATE.CONNECTED &&
              queueItem.connected?.user != currentUser.EmailAddress) ||
            entity.state === null
          }
          onClick={connectionState.connected ? handleEndCall : handleStartCall}
          startIcon={
            connectionState.connected ? (
              <FontAwesomeIcon icon={faPhoneSlash} />
            ) : connectionState.connecting ? (
              <FontAwesomeIcon spin icon={faSpinnerThird} />
            ) : (
              <FontAwesomeIcon icon={faPhone} />
            )
          }
        >
          {connectionState.connected ? "Disconnect" : "Connect"}
        </Button>

      </div>
      <Box className={classes.screenShotWrapper}>
        <FontAwesomeIcon 
          title="Click to See Latest Device Screen" size="3x" 
          className={classes.screenShotIcon} 
          icon={faImagePolaroid} 
          onClick={openDrawer}
          />
      </Box>    

    </div>
    <Drawer anchor="right" open={screenDrawerOpen} onClose={closeDrawer}>
    <Box style={{padding:8, minWidth:360}}>
      <Typography variant="h5" color="primary">
        Latest Device Screen
        <IconButton 
          onClick={closeDrawer}
          color="primary" 
          size="small" 
          style={{float:"right"}}>
            <FontAwesomeIcon icon={faTimesCircle}/>
        </IconButton>
      </Typography>

      <Divider style={{clear:"both",marginBottom:10}}/>
      <ScreenView
        className={classes.deviceScreen}
        deviceID={deviceID}
      />   
      </Box>
    </Drawer>      
    </>
  );
};

VideoFeed.defaultProps = {
  onConnecting: () => {},
  onConnected: () => {},
  onDisconnected: () => {},
};

VideoFeed.propTypes = {
  onConnecting: PropTypes.func,
  onConnected: PropTypes.func,
  onDisconnected: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  deviceID: PropTypes.string,
};

export default VideoFeed;
