import { makeStyles } from "@material-ui/core/styles";
const drawerWidth = 270;

export const useStyles = makeStyles((theme) => ({
  
  root: {

  },

}));
