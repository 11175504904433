import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Box, Divider } from "@material-ui/core";
import { useTheme, useMediaQuery } from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";

import QueueList from "../../components/CallCenter/Modules/QueueList";
import { QueueDetail } from "../../components/CallCenter/Modules/QueueDetail";
import QueueCallInitiator from "./Modules/QueueCallInitiator";
import { useStyles } from "./style";

const CallCenter = () => {
  const classes = useStyles();
  const theme = useTheme();
  const hideList = useMediaQuery(theme.breakpoints.down("md"));

  const isDesktop = useMediaQuery("(min-width:600px)");

  const facilityID = useSelector(state => state.entities?.ContextID);

  const [queueItemSelected, setQueueItemSelected] = React.useState(null);
  const handleQueueItemSelected = _id => setQueueItemSelected(_id);
  const handleCompleted = _id => setQueueItemSelected(null);

  const IsInCallList = useSelector(state =>
    typeof _.find(state.callcenter.Queue, { callID: queueItemSelected }) ==
    "undefined"
      ? false
      : true
  );

  return (
    <div className={classes.root}>
      {queueItemSelected != null && hideList ? (
        <></>
      ) : (
        <div
          className={clsx([
            classes.queueListContainer,
            "desktop-" + isDesktop.toString(),
          ])}
        >
          <Box display={{ xs: "block", sm: "none" }}>
            <Typography
              style={{ color: theme.palette.text.disabled }}
              variant="caption"
            >
              Create a Call Ticket
            </Typography>
            <QueueCallInitiator onCall={handleQueueItemSelected} />
            <Typography
              style={{ color: theme.palette.text.disabled }}
              variant="caption"
            >
              Queue List
            </Typography>
            <Divider style={{ marginBottom: 10 }} />
          </Box>
          <QueueList
            entityID={facilityID}
            onSelected={handleQueueItemSelected}
          ></QueueList>
        </div>
      )}
      <div
        className={clsx([
          classes.detailContainer,
          "details-container",
          "hidelist-" + (hideList && queueItemSelected != null).toString(),
        ])}
      >
        {!(queueItemSelected === null) && IsInCallList ? (
          <QueueDetail
            callID={queueItemSelected}
            onCompleted={handleCompleted}
          ></QueueDetail>
        ) : (
          <>
            <Box display={{ xs: "none", sm: "block" }}>
              <div className={classes.makeChoiceMessage}>
                <Typography variant="h4" component="h4">
                  Select an item from the queue list
                </Typography>
                <Typography
                  variant="h5"
                  component="h5"
                  style={{ marginTop: 25, marginBottom: 25 }}
                >
                  Or
                </Typography>
                <Typography variant="h4" component="h4">
                  Create a Ticket To a Device
                </Typography>
                <QueueCallInitiator onCall={handleQueueItemSelected} />
              </div>
            </Box>
          </>
        )}
      </div>
    </div>
  );
};

export default CallCenter;
