import React from "react";
import PropTypes from "prop-types";
import {Chip, Typography} from "@material-ui/core";

const EntityMiniAccessHolderDetails = ({accessHolderData, ...props}) => {

  return (
    (accessHolderData === null)
      ?
      <Typography variant="body" style={{color: "#b0b0b0"}}>No Access Holder Information Available</Typography>
      :
      <>
        <Typography color="primary"
                    style={{fontWeight: "bold"}}>{accessHolderData?.contactInfo?.firstName} {accessHolderData?.contactInfo?.lastName}</Typography>
        <Chip alt="Mode" title="Mode" variant="outlined" size="small" color="primary" label={accessHolderData?.mode}
              style={{marginRight: 5}}/>
        <Chip alt="Parking Status" title="Parking Status" variant="outlined" size="small" color="primary"
              label={(accessHolderData?.parked) ? "Parked" : "Not Parked"}/>
      </>
  );
};

EntityMiniAccessHolderDetails.defaultProps = {accessHolderData: null,};
EntityMiniAccessHolderDetails.propTypes = {
  accessHolderData: PropTypes.object,

};

export default EntityMiniAccessHolderDetails;
