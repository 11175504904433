import { makeStyles } from "@material-ui/core/styles";
const drawerWidth = 270;

export const useStyles = makeStyles((theme) => ({
  
  root: {
    //border:"1px solid purple",
    overflowX: "hidden",
  },
  entityCardWrapper: {
    paddingRight: 5,
    animation: "flipInX",
    animationDuration: "1s",
  },
  credentialSearch: {
    //animation: "fadeInLeft",
    //animationDuration: "0.5s",
  },
  transactionPanel: {
    marginTop: 15,
    // animation: "fadeInRight",
    // animationDuration: "0.5s",
  },
  videoFeedWrapper: {
    // animation: "fadeInRight",
    // animationDuration: "0.5s",
    margin: 5,
    float: "right",
  },
  callCenterSearch: {
    backgroundColor: theme.palette.background.default,
  },
  ioStatus: {
    textAlign: "left",
    float: "left",
  },
  actionButton: {
    // maxWidth:130,
    marginBottom:4,
    marginRight:5,
    //minWidth:120,
    '&.hidelist-true': {
      // fontSize: 10
      lineHeight:2,
    },      
  },



  //the "done" button
  actionsPanel: {
    //display: "flex",
    position: "fixed",
    //minWidth:510,
    width:"100vw",
    minWidth:"100vw",
    bottom: 36,
    minHeight: 50,
    padding: 2,
    paddingRight: 6,
    paddingTop: 4,
    right: 0,
    border: "1px solid #c0c0c0",
    textAlign: "right",
    backgroundColor: theme.palette.background.paper,
    '&.hidelist-true': {
     // width: `calc(100% - ${drawerWidth}px)`,
    },
    '&.hidelist-false': {
      //width:"calc(100% - 527px)", 
    },
  },
  actionPanelsShift: {
      [theme.breakpoints.up("sm")]: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
  },
  printTicketBtn: {
    marginRight: theme.spacing(1),
  },
  negotiateTicketBtn: {
    marginRight: theme.spacing(1),
  },
  openGateBtn: {
    marginRight: theme.spacing(1),
  },
  applyValidationBtn: {
    marginRight: theme.spacing(1),
  },
  deviceScreen: {
    
  },
}));
