import {shallowEqual, useSelector} from "react-redux";
import {FindEntity} from "../../../state/slices/entities";

const useCallCenterTransaction = ({entityID}) => {
  return useSelector((state) => {
    const entity = FindEntity(state.entities?.EntityList ?? [], entityID);
    return {
      entityID: entity?.entityid,
      ...entity?.callcenter
    };
  }, shallowEqual);
};

export default useCallCenterTransaction;
