import React, { useState } from "react";

import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useMediaQuery } from "@material-ui/core";

import moment from "moment";

import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import apiClient from "../../../auth/apiClient";
import CallCenterService from "../../../services/CallCenterService";
import { useStyles } from "./QueueCallInitiator.style";
import DevicesSelect from "../../Dropdowns/DevicesSelect/DevicesSelect.index";
import _ from "lodash";
import { shallowEqual, useSelector } from "react-redux";

const callCenterService = new CallCenterService(apiClient);

export const QueueCallInitiator = ({ onCall }) => {
  const enqueueSnackbar = useEnqueueSnackbar();

  const classes = useStyles();
  const isDesktop = useMediaQuery("(min-width:600px)");

  const [deviceToCall, setDeviceToCall] = useState("");

  const createCall = async () => {
    if (deviceToCall === "") {
      enqueueSnackbar("Please select a device first", {
        variant: "error",
        tag: "FailedtoSelectADevice",
      });
      return;
    }
    try {
      const result = await callCenterService.queueCall({
        DeviceID: deviceToCall,
        RequestTime: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ssZ"),
      });
      setDeviceToCall("");
      if (result?.data?.callID) onCall(result?.data?.callID);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to create a call entry", {
        variant: "error",
        tag: "FailedToCreateACallEntry",
      });
    }
  };

  const handleDeviceSelect = (event) => {
    setDeviceToCall(event.target.value);
  };

  const disabledDevicesList = useSelector(
    (state) => _.orderBy(state.callcenter.Queue, ["callStart"], ["asc"]),
    shallowEqual
  );

  return (
    <div className={classes.root}>
      <DevicesSelect
        onSelect={handleDeviceSelect}
        value={deviceToCall}
        disabledDevicesList={disabledDevicesList}
      />
      <Button
        variant="contained"
        className={classes.buttonCreate}
        color="primary"
        size="small"
        onClick={createCall}
        disabled={deviceToCall === ""}
      >
        {isDesktop ? <span>Create</span> : <AddIcon />}
      </Button>
    </div>
  );
};

QueueCallInitiator.defaultProps = {};
QueueCallInitiator.propTypes = {};

export default QueueCallInitiator;
