import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { faEnvelopeOpen } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CredentialService from "../../../services/CredentialService";
import { useStyles } from "./styles";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { isEmpty } from "lodash";
import clsx from "clsx";

// TODO: for now, emailing exists on the credential-service
// will need to move this to routine-service once it is up
const credentialService = new CredentialService(apiClient);

const CredentialEmailButton = ({
  ["data-id"]: dataID,
  body,
  subject,
  to,
  from,
  displayname,
  replyTo,
  ccReplyTo,
  qrCode,
  toAddressMessage,
  fromAddressMessage,
}) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();

  const handleSendEmail = async () => {
    if (!to || isEmpty(to)) {
      enqueueSnackbar(toAddressMessage, {
        variant: "warning", tag: "missingToAddress"
      });
      return;
    }
    body = body.replace("<Barcode>", "<div>" + qrCode + "</div>");
    var htmlString =
      "<meta name='viewport' content='width=device-width, initial-scale=1.0'><div style='width:100%;height:100%;text-align:center'><div style='margin: 0 auto;'>" +
      qrCode +
      "</div></div>";
    var emailRequest = {
      CCReplyTo: ccReplyTo === "true" || ccReplyTo === true ? true : false,
      DisplayName: displayname,
      ToAddress: to,
      ReplyToAddress: replyTo,
      Subject: subject,
      Body: body,
      Attachments: [
        {
          FileContent: htmlString,
          FileName: "credential",
          Extension: "html",
        },
      ],
    };
    try {
      await credentialService.SendEmail(emailRequest);
      enqueueSnackbar("Successfully sent email", { variant: "success" });
    } catch {
      enqueueSnackbar("Failed to send email", { variant: "error", tag: "emailFailure" });
    }
  };

  return (
    <Button
      className={clsx("email-btn")}
      variant="contained"
      color="primary"
      size="small"
      aria-label={"email-button"}
      title="E-Mail Credential"
      onClick={handleSendEmail}
      data-testid="credentialEmailButton"
    >
      <FontAwesomeIcon
        icon={faEnvelopeOpen}
        className={clsx("font-awesome-icon")}
        size="lg"
      />
    </Button>
  );
};

CredentialEmailButton.defaultProps = {
  settings: {},
  toAddressMessage: "No 'To' address provided",
  //fromAddressMessage: "No 'From' address provided",
};

CredentialEmailButton.propTypes = {
  body: PropTypes.string,
  subject: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  //from: PropTypes.string.isRequired,
  ccReplyTo: PropTypes.bool,
};

export default CredentialEmailButton;
