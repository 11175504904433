import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import _ from "lodash";

import useTimer from "../../../hooks/useTimer";

import QueueItemCard from "./QueueItemCard";

export const QueueList = ({ onSelected, onItemRemoved }) => {
  const queueList = useSelector((state) =>
    _.orderBy(state.callcenter.Queue, ["callStart"], ["asc"])
  );
  const handleSelected = (_id) => onSelected(_id);

  const { handleStart, handlePause } = useTimer(44);

  useEffect(() => {
    handleStart();
    return () => {
      handlePause();
    };
  }, [queueList]);

  return (
    <>
      {queueList?.length === 0 && (
        <div style={{ color: "silver", padding: 10, textAlign: "center" }}>
          No tickets in the queue
        </div>
      )}

      {queueList?.map((qi, i) => (
        <QueueItemCard key={i} onSelect={handleSelected} callInfo={qi} />
      ))}
    </>
  );
};

export default QueueList;

QueueList.defaultProps = {
  onSelected: () => {},
  onItemRemoved: () => {},
};

QueueList.propTypes = {
  onSelected: PropTypes.func,
  onItemRemoved: PropTypes.func,
};
