import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  rootCard: {
    marginTop: theme.spacing(2),
  },
  smallHeader: { height: "30px" },
  credentialDropdown: {
    minWidth: "200px",
    marginBottom: theme.spacing(2),
  },
  spaceBetween: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
  },
  credentialTypeChip: {
    minWidth: "100px",
    marginLeft: theme.spacing(1),
  },
  credReferenceText: {
    paddingTop: "5px",
  },
  ticketDetail: {
    border: "none",
    boxShadow: "none"
  },
}));
