import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  noCredentials: {
    textDecoration: "italics"
  },

  credentialCategory: {
    fontWeight: "bold",
    color: theme.palette.grey[500]
  },

  credentialDividerSpacing: {
    marginTop: "0.5em"
  },

  credentialOption: {
    paddingLeft: "2em"
  },

  select: {
    minWidth: "150px"
  },
}));
