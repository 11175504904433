import React, { useCallback, useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardContent,
  Drawer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./EntityTransactionPanel.style";
import * as c from "../../../constants";
import Moment from "react-moment";
import apiClient from "../../../auth/apiClient";
import ContractService from "../../../services/ContractService";
import EntityMiniAccessHolderDetails from "./EntityMiniAccessHolderDetails";
import { FindEntity } from "../../../state/slices/entities";
import EntityLPRTransactionDetails from "./EntityLPRTransactionDetails";
import useCurrentFacilityTimezone from "../../../hooks/useCurrentFacilityTimezone";
import useCallCenterTransaction from "../../CallCenter/Hooks/useCallCenterTransaction";
import _ from "lodash";
import { SearchIcon } from "@material-ui/data-grid";
import clsx from "clsx";
import Search from "../Search/Search.index";
import { useFlags } from "launchdarkly-react-client-sdk";
import useHasPermissions from "../../../hooks/useHasPermissions";

const EntityTransactionPanel = ({entityID, callID, ...props}) => {
  const classes = useStyles();
  const lprInformation = useSelector((state) => FindEntity(state.entities?.EntityList ?? [], entityID)?.state?.Peripherals?.Lpr, shallowEqual);
  const FacilityID = useSelector((state) => state.entities.ContextID);

  const { convertUtcDate } = useCurrentFacilityTimezone();
  const { hasPermissions } = useHasPermissions();
  const credentialSearchPermission = hasPermissions([
    "callcenter.credentialsearch",
  ]);
  const { callCenterSearchV2 } = useFlags();

  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const [accessHolderData, setAccessHolderData] = useState(null);

  const contractService = new ContractService(apiClient);
  const callCenterTransaction = useCallCenterTransaction({entityID: entityID});

  const closeSearchDrawer = () => {
    setSearchDrawerOpen(false);
  }

  const openSearchDrawer = () => {
    setSearchDrawerOpen(true);
  }

  const nullFilter = (d) => {
    return d === "Invalid date" ? "" : d;
  };

  function currencyFormat(num) {
    try {
      return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } catch (e) {
      return null;
    }
  }
  const getCredentialTypeWithKey = (typeValue) => {
    if (typeValue == undefined) {
      return "N/A";
    }
    const typeName = Object.keys(c.CREDENTIAL_TYPES)[
      Object.values(c.CREDENTIAL_TYPES).findIndex((val) => val == typeValue)
    ];
    return `${typeName} (${typeValue})`;
  };

  const getAccessHolderDetails = useCallback(
    async (_accessHolderID) => {
      try {
        const response = await contractService.getAccessHolder(
          FacilityID,
          _accessHolderID
        );
        setAccessHolderData(response.data);
      } catch (err) {
        setAccessHolderData(null);
      } finally {
        //do nothing
      }
    },
    [callCenterTransaction?.CredentialInUse]
  );

  useEffect(() => {
    const accessHolderID = callCenterTransaction?.AccessHolderID;
    if (!_.isUndefined(accessHolderID)) {
      getAccessHolderDetails(accessHolderID);
    }
  }, [callCenterTransaction?.AccessHolderID]);

  const convertResultCode = (code) => {
    var _resultCode = "";
    switch (code) {
      case 1:
        _resultCode = "Anti Pass Back";
        break;
      case 2:
        _resultCode = "Valid";
        break;
      case 3:
        _resultCode = "Soft Anti Pass Back";
        break;
      case 4:
        _resultCode = "Hard Anti Pass Back";
        break;
      case 5:
        _resultCode = "Not Found";
        break;
      case 6:
        _resultCode = "Access Group Error";
        break;
      case 7:
        _resultCode = "Locked Out";
        break;
      case 8:
        _resultCode = "Expired Credential";
        break;
      case 9:
        _resultCode = "Not Valid Yet";
        break;
      case 10:
        _resultCode = "Entered With Different Credential";
        break;
      case 11:
        _resultCode = "Inactive";
        break;
      case 12:
        _resultCode = "Valet Access";
        break;
      case 13:
        _resultCode = "Invalid Valet Ticket";
        break;
      case 14:
        _resultCode = "Access Denied - Insufficient Fund Balance";
        break;
      case 15:
        _resultCode = `Access Denied - ${callCenterTransaction.AccessGroupName ??
          `Shared Account`} is Full`;
        break;
      case 21:
        _resultCode = "Hard Nest Violation";
        break;
      case 22:
        _resultCode = "Soft Nest Violation";
        break;
      case 0:
      default:
        _resultCode = "N/A";
        break;
    }
    return _resultCode;
  };

  return (
    <Card className={classes.root} style={{ width: "100%s" }}>
      <CardContent>
        <div className={classes.header}>
        <Typography className={classes.title} gutterBottom>
          Transaction Information
        </Typography>

        {credentialSearchPermission && callCenterSearchV2 && (
          <Button
            className={clsx("open-search-drawer", classes.searchButton)}
            variant="outlined"
            color="primary"
            onClick={openSearchDrawer}>
            <SearchIcon className={classes.searchIcon} />
            Search
          </Button>
        )}
        
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TransactionTableRow name="Access Holder">
                <EntityMiniAccessHolderDetails
                  accessHolderData={accessHolderData}
                />
              </TransactionTableRow>
              <TransactionTableRow name="Access Holder ID">
                {callCenterTransaction?.AccessHolderID}
              </TransactionTableRow>
              <TransactionTableRow name="Balance">
                {currencyFormat(callCenterTransaction?.UpdatedRateCost) ?? "N/A"}
              </TransactionTableRow>
              <TransactionTableRow name="Credential">
                {callCenterTransaction?.CredentialReference ??
                  callCenterTransaction?.TicketID ??
                  "None Presented"}
              </TransactionTableRow>
              <TransactionTableRow name="Credential Type">
                {getCredentialTypeWithKey(
                  callCenterTransaction?.TicketID
                    ? c.CREDENTIAL_TYPES.TICKET
                    : callCenterTransaction?.CredentialType
                )}
              </TransactionTableRow>
              <TransactionTableRow name="Entry Date/Time">
                <Moment
                  filter={nullFilter}
                  format="dddd, MMM D, YYYY hh:mm:ss A zz"
                >
                  {convertUtcDate(callCenterTransaction?.EntryDateTime) ?? null}
                </Moment>
              </TransactionTableRow>
              <TransactionTableRow name="Exit Date/Time">
                <Moment
                  filter={nullFilter}
                  format="dddd, MMM D, YYYY hh:mm:ss A zz"
                >
                  {convertUtcDate(callCenterTransaction?.ExitDateTime) ?? null}
                </Moment>
              </TransactionTableRow>
              <TransactionTableRow name="Result Code">
                {convertResultCode(callCenterTransaction?.ResultCode ?? "N/A")}
              </TransactionTableRow>
              <TransactionTableRow name="Error Message">
                {callCenterTransaction?.ErrorMessage}
              </TransactionTableRow>
              <TransactionTableRow name="Overage">
                {callCenterTransaction?.CredentialType === c.CREDENTIAL_TYPES.PREPAID
                  ? callCenterTransaction?.HasOverage.toString() ?? "N/A"
                  : "N/A"}
              </TransactionTableRow>
              <TransactionTableRow name="Total Surcharges">
                {currencyFormat(callCenterTransaction?.TotalSurcharges)}
              </TransactionTableRow>
              <TransactionTableRow name="Total Discounts">
                {currencyFormat(callCenterTransaction?.TotalDiscounts)}
              </TransactionTableRow>
              <TransactionTableRow name="License Plate" padding="none">
                <EntityLPRTransactionDetails lprInformation={lprInformation}/>
              </TransactionTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      
      {/* Search drawer */}
      <Drawer
          PaperProps={{ classes: { root: clsx('search-drawer', classes.drawer) } }}
          anchor="right"
          open={searchDrawerOpen}
          onClose={closeSearchDrawer}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div className={classes.drawerContent}>
            <Search
              callID={callID}
              entityID={callCenterTransaction.entityID}
              onSelect={(value) => alert("value selected: ", value)}/>
          </div>
          <div className={classes.drawerFooter}>
            <Button
              variant="contained"
              color="primary"
              onClick={closeSearchDrawer}
              className={clsx("close-search-drawer", classes.drawerCloseBtn)}>
              Close
            </Button>
          </div>
        </Drawer>

    </Card>
  );
};

const TransactionTableRow = ({ name, children, padding }) => {
  return (
    <TableRow
      hover
      style={{
        marginTop: 2,
        display: !children || children == "N/A" ? "none" : "",
      }}
    >
      <TableCell component="th" scope="row" style={{ fontSize: "smaller" }}>
        {name}
      </TableCell>
      <TableCell
        align="right"
        style={{ fontSize: "smaller" }}
        padding={padding ?? "default"}
      >
        {children}
      </TableCell>
    </TableRow>
  );
};

EntityTransactionPanel.defaultProps = { entityID: null, onSuccess: undefined };
EntityTransactionPanel.propTypes = {
  entityID: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default EntityTransactionPanel;
