import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

export default function UnarchiveButton({
  callID,
  credential,
  onUnarchive,
  ...props
}) {

  return (
    <Button
      {...props}
      onClick={onUnarchive}>
      Unarchive
    </Button>
  );
}

UnarchiveButton.propTypes = {
  callID: PropTypes.string.isRequired,
  credential: PropTypes.object.isRequired,
  onUnarchive: PropTypes.object.isRequired,
};
