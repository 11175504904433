import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { useStyles } from "./CredentialSelect.style";
import { FormControl, InputLabel, ListSubheader, MenuItem, Select } from "@material-ui/core";
import _ from "lodash";

export const CredentialSelect = ({
  credentials,
  defaultSelection,
  onSelect
}) => {
  const classes = useStyles();

  const [selectedCredential, setSelectedCredential] = useState("");
  const [categorizedCredentials, setCategorizedCredentials] = useState({});

  const defaultLabel = "Select a credential";
  const [labelContent, setLabelContent] = useState(defaultLabel);
  const setSelection = useCallback((credential) => {
    setSelectedCredential(credential);
    setLabelContent(credential.credentialType ?? defaultLabel);
    if (!_.isNil(onSelect)) {
      onSelect(credential);
    }
  }, [onSelect]);

  const massageCredentials = useCallback(() => {
    const localCategorizedCredentials = {};
    for (const credential of credentials) {
      if (_.isNil(localCategorizedCredentials[credential.credentialType])) {
        localCategorizedCredentials[credential.credentialType] = [];
      }
      const typedCredentials = localCategorizedCredentials[credential.credentialType];
      typedCredentials.push(credential);

    }
    setCategorizedCredentials(localCategorizedCredentials);
  }, [credentials]);

  useEffect(() => {
    massageCredentials(credentials);
  }, [massageCredentials, credentials]);

  useEffect(() => {
    if (!_.isNil(defaultSelection) && !_.isNil(categorizedCredentials)) {
      const credential = _.find(categorizedCredentials[defaultSelection.credentialtype], { 
        "credentialID": defaultSelection.credentialid
      })
      if (!_.isNil(credential)) {
        setSelection(credential);
      }
    }
  }, [categorizedCredentials, defaultSelection, setSelection])

  const buildMenuOptions = useCallback(() => {

    if (_.isEmpty(categorizedCredentials)) {
      return (
        <MenuItem
          key={`credentialType_empty`}
          value=""
          disabled
          >
          <em>No credentials</em>
        </MenuItem>
      );
    }

    const output = [];
    let followUpCategory = false;
    for (const credentialCategory in categorizedCredentials) {
      const category = categorizedCredentials[credentialCategory];
      output.push(
        <ListSubheader
          key={`credentialType_${category[0].credentialType}`}
          className={clsx(
            classes.credentialCategory,
            followUpCategory ? classes.credentialDividerSpacing : ""
          )}
          // clicking subheader initiates a select action
          // https://github.com/mui/material-ui/issues/18200
          // TODO: this might be messing with accessability/keyboard navigation FYI
          onClickCapture={(e) => e.stopPropagation()}
          >
          {category[0].credentialType}
        </ListSubheader>
      )
      followUpCategory = true;
      for (const credential of category) {
        output.push(
          <MenuItem
            key={`credential_${credential.credentialReference}`}
            className={classes.credentialOption}
            value={credential}
            data-credential={`credential_${credential.credentialReference}`}>
            {credential.credentialReference}
          </MenuItem>
        );
      }
    }

    return output;
  }, [categorizedCredentials]);
  
  return (
    <div>
      <FormControl variant="outlined" size="medium">
        <InputLabel
          htmlFor="group-native-select">
          {labelContent}
        </InputLabel>
        <Select
          id="group-native-select"
          classes={{ select: classes.select }}
          label={labelContent}
          value={selectedCredential}
          onChange={(event) => setSelection(event.target.value)}
          inputProps={{
            name: "credential-list",
            id: "credential-list",
          }}>
          {buildMenuOptions()}
        </Select>
      </FormControl>
    </div>
  );
};