import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Grid,
} from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import CardOnFileForm from "../../Forms/CreditCardOnFile";
import _ from "lodash";
import { isUndefined } from "lodash";

export default function CreditCardOnFileButton({
  contractID,
  accessHolderID,
  disabled,
  toggleModal,
  modalOpen,
}) {
  const classes = useStyles();

  return (
    <>
      <Button
        fullWidth
        className={clsx("card-on-file-btn")}
        color="primary"
        variant="contained"
        onClick={toggleModal}
        disabled={disabled}
      >
        Card on File
      </Button>
      <Dialog
        className={classes.editDialog}
        maxWidth="xs"
        fullWidth
        open={modalOpen}
        onClose={toggleModal}
        role="card-on-file-form"
      >
        <DialogContent className="credit-card-dialog">
          <CardOnFileForm
            contractID={contractID}
            accessHolderID={accessHolderID}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

CreditCardOnFileButton.propTypes = {
  className: PropTypes.string,
  contractID: PropTypes.string,
  entityID: PropTypes.string,
  cardOnFile: PropTypes.object,
};
