import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  historyDialog: {
    minWidth: "50vw",
  },
  historyBtn: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 5,
    paddingRight: 5,
    [theme.breakpoints.down("small")]: {
      padding: 0,
    },
  },
  credentialCell: {
    padding: 0,
  },
  credentialContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      marginLeft: theme.spacing(1),
    },
  },
  resultStatus: {
    '&.status.enter': {
      color:theme.palette.success.dark,
    },
    '&.status.exit': {
      color:theme.palette.primary.main,
    },
    '&.status.backout-with-ticket':{
      
    },
    '&.status.errorHighlight':{
      color: theme.palette.error.dark,
    },
    '&.status.warningHighlight':{
      color: theme.palette.warning.main
    }
  },
}));
