import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, LinearProgress, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarSide } from "@fortawesome/pro-regular-svg-icons";
import { faCaretUp } from "@fortawesome/pro-duotone-svg-icons";
import EditIcon from "@material-ui/icons/Today";

import _ from "lodash";
import clsx from "clsx";
import moment from "moment";

import { useStyles } from "./TicketResult.style";
import TicketService from "../../../../services/TicketService";
import apiClient from "../../../../auth/apiClient";
import SquareChip from "../../../SquareChip";
import CredentialStatusChange from "../../../Credentials/CredentialStatusChange";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import EditTicketForm from "../../../TicketComponents/EditTicketForm";
import useCurrentFacilityTimezone from "../../../../hooks/useCurrentFacilityTimezone";
import SendCredentialButton from "../../Modules/Buttons/SendCredentialButton";
import UnarchiveButton from "../../Modules/Buttons/UnarchiveButton";
import { useFlags } from "launchdarkly-react-client-sdk";
import CallCenterService from "../../../../services/CallCenterService";

const ticketService = new TicketService(apiClient);
const callCenterService = new CallCenterService(apiClient);

const statusOptions = [
  { id: "void", name: "VOID" },
  { id: "payment", name: "PAID" },
  { id: "exit", name: "OUT" },
  { id: "enter", name: "IN" }
];

export const TicketResult = ({
  entityID,
  callID,
  ticket,
  withLPR,
  searchTerm
}) => {
  
  const { routinesInventoryArchive } = useFlags();
  const classes = useStyles();
  const [ticketData, setTicketData] = useState();
  // TODO: let parent control expansion? Only allow one open at a time?
  // Works as is, each row can be expanded and closed independently
  const [detailExpanded, setDetailExpanded] = useState(false);
  const [replacementExpression, setReplacementExpression] = useState(null);

  const [editingEndDate, setEditingEndDate] = React.useState(false);

  const EditTicketsPermission = useHasPermissions(["inventory.edit"]);
  const enqueueSnackbar = useEnqueueSnackbar();

  // TODO: should we use timezone of the device?
  // would change existing expectations on call center tickets search, however
  const { convertUtcDate } = useCurrentFacilityTimezone();

  const inGarageToString = (status) => {
    if (_.isUndefined(status)) {
      return "unknown";
    }
    return status ? "in" : "out";
  };

  const getFormattedTime = useCallback((date) => {
    return convertUtcDate(date).format("llll");
  }, [convertUtcDate]);

  const retrieveTicket = useCallback(async () => {
    try {
      const response = await ticketService.retrieveTicketById(ticket.credentialreference);
      response.data.credentialType = "ticket";
      response.data.credentialReference = ticket.credentialreference;
      setTicketData(response.data);
    } catch (err) {
      console.error(err);
    }
  }, [ticket]);

  const openDetail = useCallback(() => {
    setDetailExpanded(true);
    if (_.isNil(ticketData)) {
      retrieveTicket();
    }
  }, [ticketData, retrieveTicket]);

  const closeDetail = () => {
    setDetailExpanded(false);
  }

  const handleKeyDown = useCallback((event) => {
    if (!detailExpanded && (event.key === "Enter" || event.key === " ")) {
      event.preventDefault();
      openDetail();
    }
  }, [detailExpanded, openDetail])

  const handleStatusSubmit = useCallback(async ({ status, reason }) => {
    ticketData.activityDate = moment().format(
      "YYYY-MM-DDTHH:mm:ssZ"
    );
    if (status?.toLowerCase() === "void") {
      ticketData.expiredDate = ticketData.activityDate;
    }
    ticketData.status = status;
    ticketData.statusChangeReason = reason;

    await handleUpdateTicket(ticketData);
  }, [ticketData]);

  const handleUpdateTicket = async ticket => {
    let response;
    try {
      response = await ticketService.updateTicket(ticket);
    } catch (err) {
      if (
        err.response?.status === 400 &&
        typeof err.response?.data === "string"
      ) {
        enqueueSnackbar(err.response.data, { variant: "warning" });
        return;
      } else {
        enqueueSnackbar("Failed to update status of ticket", {
          variant: "error",
          tag: "FailedToUpdateTicketStatus"
        });
        return;
      }
    }

    setTicketData(response.data);
    enqueueSnackbar("Ticket successfully updated", { variant: "success" });
  };

  const handleUnarchive = async () => {
    try {
      await callCenterService.unarchiveTicket(ticketData.ticketID);
      retrieveTicket();
      enqueueSnackbar(`Ticket is unarchived.`, {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "There was an issue unarchiving the ticket. Please try again.",
        {
          variant: "error",
          tag: "ErrorUnarchivingTicket",
        }
      );
    }
  };

  const HighlightSearch = ({ children = '' }) => {
    if (_.isNil(replacementExpression)) {
      return children;
    }

    const parts = String(children).split(replacementExpression);
    return parts.map((part, index) => (
      replacementExpression.test(part) 
        ? <mark key={`mark_${index}`}>{part}</mark> 
        : <span key={`span_${index}`}>{part}</span>
    ));
  };

  useEffect(() => {
    if (_.isEmpty(searchTerm)) { 
      return;
    }

    const escapedSearchTerm = searchTerm.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    setReplacementExpression(new RegExp("(" + escapedSearchTerm + ")", "gi"));
  }, [searchTerm])

  return detailExpanded ? 
    _.isNil(ticketData)
      ? <Box className={classes.searchingContainer}>
          <LinearProgress />
        </Box>
      : (
        <div className={classes.expandedDetailsContainer}>
          <div className={classes.expandedDetailsContainerHeader}>
            <Typography data-id="ticket-id" variant="h6" color="primary">
              {ticketData.ticketID}
            </Typography>
            {ticketData.status != "Backout without ticket" &&
              ticketData.status != "Backout with ticket" && (
                ticketData.transactionState == 0 && (
                <CredentialStatusChange
                  data-id="ticket-status-split-btn"
                  disabled={!EditTicketsPermission}
                  className={classes.statusSelector}
                  statusOptions={statusOptions}
                  onSubmit={handleStatusSubmit}
                  defaultSelected={
                    ticketData.status?.toLowerCase() === "lost ticket" || 
                    ticketData.status?.toLowerCase() === "payonentry"
                      ? "Payment"
                      : ticketData.status
                  }
                />
              ))}
          </div>
          <Grid container className={classes.datesContainer} spacing={6}>
            <Grid item xs={12} md={6}>
              <Typography
                name="start time"
                color="textSecondary"
                className={classes.timeLabel}>
                Start Time
              </Typography>
              <Typography
                data-id="issued-date"
                variant="h6"
                name="issued date">
                {getFormattedTime(ticketData.issuedDate)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                color="textSecondary"
                className={classes.timeLable}>
                End Time
              </Typography>
              {ticketData.transactionState == 1 ? (
                <Typography
                  data-id="archived-status"
                  variant="h6"
                  name="archived-status"
                >
                  Archived
                </Typography>
              ) : (
                editingEndDate && EditTicketsPermission ? (
                  <EditTicketForm
                    currentTicket={ticketData}
                    cancelEditing={() => setEditingEndDate(false)}
                    endEditing={() => setEditingEndDate(false)}
                    onTicketSubmit={handleUpdateTicket}
                  />
                ) : (
                  <Typography
                    data-id="expired-date"
                    variant="h6"
                    name="expired date"
                  >
                    {ticketData.expiredDate === null ||
                      ticketData.status?.toLowerCase() === "enter" ? (
                      <span className={classes.active} alt="Close Out Ticket">
                        Active
                      </span>
                    ) : (
                      getFormattedTime(ticketData.expiredDate)
                    )}
                    {EditTicketsPermission && (
                      <IconButton
                        name="edit button"
                        onClick={() => setEditingEndDate(true)}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </Typography>
                )
              )}
            </Grid>
          </Grid>
          <div className={classes.footerDiv}>
            <Button 
              startIcon={<FontAwesomeIcon icon={faCaretUp}/>}
              className={clsx(classes.collapseTicket, "collapse")}
              onClick={closeDetail}>
                Collapse
            </Button>
            {routinesInventoryArchive && (
            ticketData.transactionState == 1 ? (
              <UnarchiveButton
              className={classes.sendCredentialsBtn}
              callID={callID}
              credential={ticketData}
              onUnarchive={handleUnarchive}
              variant="contained"
              color="primary" />
            ) : (
              <SendCredentialButton
              className={classes.sendCredentialsBtn}
               callID={callID}
               credential={ticketData}
               deviceID={entityID}
               variant="contained"
               color="primary" />
            )
            )}
          </div>
        </div>
  ) : (
    <div tabIndex={0}
      className={clsx(classes.credentialRow, classes.clickableRow)}
      onKeyDown={handleKeyDown}
      onClick={openDetail}
      data-credential={ticket.credentialreference}
      data-credential-status={inGarageToString(ticket.ingarage)}>
      <Grid container>
        <Grid item xs={12} lg={10} className={classes.credentialGrid}>
          <Typography className={classes.credentialReference}>
            <HighlightSearch>{ticket.credentialreference}</HighlightSearch>
          </Typography>
        </Grid>

        <Grid item xs={12} lg={2} className={classes.credentialChip}>
        {ticket.transactionstate == 1 ? (
          <SquareChip uppercase
            mode="unselected"
            text={"Archived"}
          />
        ) : (
          <SquareChip uppercase
            mode={_.isUndefined(ticket.ingarage) 
              ? "warning"
              : ticket.ingarage ? "info" : "unselected"}
              text={inGarageToString(ticket.ingarage)}
          />
        )}
        </Grid>

        { withLPR && ( // TODO: might need to redesign this, retest with LPR later. Right now renders on new row.
          <Grid item container xs={12}>
            <Grid item xs={1} lg={2}>
              <FontAwesomeIcon icon={faCarSide} />
            </Grid>
            <Grid item xs={11} lg={10}>
              <Typography>{ticket.cameraread ? ticket.cameraread : "Not Available"}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};