import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  screenImage: {
    cursor: "pointer"
  },
  screenImageModalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: "pointer",
    width: "60vw",
    [theme.breakpoints.down("md")]: {
      width: "95vw",
    },
    padding: theme.spacing(0),
    display: "flex",
    justifyContent: "center"
  },
  modalImage: {
    width: "100%",
    maxHeight: "85vh",
    objectFit: "contain"
  }
}));
