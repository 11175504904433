import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "0.75em"
  },

  searchIcon: {
    marginRight: "0.25em"
  },

  drawer: {
		width: "100%",
		minWidth: "100%",
		[theme.breakpoints.up('sm')]: {
			width: "35vw",
			minWidth: 475,
      maxWidth: 750,
			flexShrink: 0,
		},
  },

  drawerContent: {
    flexGrow: 1,
    padding: "20px 20px 0 20px",
    height: "95vh"
  },

  drawerFooter: {
    display: "flex",
    padding: "10px",
    borderTop: `solid 2px ${theme.palette.divider}`
  },

  drawerCloseBtn: {
    width: "120px",
    marginLeft: "auto"
  },

  title: {
    fontSize: "90%",
    color: theme.palette.text.secondary,
  },
}));
