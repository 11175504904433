import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    paddingRight: theme.spacing(2),
  },
  textBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  plateText: {
    [theme.breakpoints.up("sm")]: {
      marginRight: "4px",
    },
  },
  imageBox: {
    minWidth: "90px",
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(3),
    },
  },
  image: {
    cursor: "pointer",
  },
}));
