import React, { useCallback,  useState } from "react"
import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard as accessHolderIcon } from "@fortawesome/pro-regular-svg-icons";

import { useStyles } from "./CredentialResult.style";
import { AccessHolderDetail } from "./AccessHolderDetail.index";
import SquareChip from "../../../SquareChip";

export const CredentialResult = ({
  callID,
  entityID,
  credential
}) => {
  const classes = useStyles();

  // TODO: let parent control expansion? Only allow one open at a time?
  // Works as is, each row can be expanded and closed independently
  const [detailExpanded, setDetailExpanded] = useState(false);

  const openDetail = () => {
    setDetailExpanded(true);
  }

  const closeDetail = () => {
    setDetailExpanded(false);
  }

  const handleKeyDown = useCallback((event) => {
    if (!detailExpanded && (event.key === "Enter" || event.key === " ")) {
      event.preventDefault();
      openDetail();
    }
  }, [detailExpanded])

  return detailExpanded 
    ? <AccessHolderDetail 
        callID={callID} 
        entityID={entityID} 
        accessHolderId={credential.accessholderid}
        defaultCredential={credential}
        close={closeDetail}/>
    : <div tabIndex={0}
        className={clsx(classes.resultRow, classes.clickableRow)}
        onKeyDown={handleKeyDown}
        onClick={openDetail}
        data-credential={credential.credentialreference}
        data-credential-type={credential.credentialtype}
        data-accessholder-name={credential.lastname 
          ? credential.firstname.concat(" ", credential.lastname) 
          : credential.firstname}>
        <Grid container>
          <Grid item xs={4} className={classes.dataRow}>
            <Typography>{credential.credentialreference}</Typography>
          </Grid>
          <Grid item xs={4} className={classes.dataRow}>
            <FontAwesomeIcon icon={accessHolderIcon} className={classes.ahIcon} />
            <Typography>
              {credential.lastname 
              ? credential.firstname.concat(" ", credential.lastname) 
              : credential.firstname}  
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.credentialChipContainer}>
          {credential && credential.transactionstate == 1 && (
          <SquareChip
              size="small"
              uppercase
              className={classes.credentialChip}
              mode="unselected"
              text={"Archived"}
            />
          )}
          </Grid>
          <Grid item xs={2} className={classes.credentialChipContainer}>
            <SquareChip
              size="small"
              uppercase
              className={classes.credentialChip}
              mode="info"
              text={credential.credentialtype}
            />
          </Grid>
        </Grid>
      </div>
};