import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import EditBalanceForm from "../../Forms/EditBalance";
import Title from "../../Title";
import ColoredLine from "../../ColoredLine";

export default function EditBalanceButton({
  className,
  currentBalance,
  setCurrentBalance,
  contractID,
  entityID,
  fundType,
  fundID,
  setFundID,
  disabled
}) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <Button
        fullWidth
        className={clsx("edit-balance-btn", className)}
        color="primary"
        variant="contained"
        onClick={toggleModal}
        disabled={disabled}
      >
        Edit Balance
      </Button>
      <Dialog
        className={classes.editDialog}
        maxWidth="xs"
        fullWidth
        open={modalOpen}
        onClose={toggleModal}
      >
        <DialogContent className="edit-balance">
          <div className={clsx("header", classes.header)}>
            <Title className={clsx("accessgroup-title")} noGutter>
              Edit Balance
            </Title>
            <Typography variant="h4" align="right">
              ${currentBalance?.toFixed(2)}
            </Typography>
          </div>
          <ColoredLine />
          <Typography variant="subtitle1" align="right">
            Current
          </Typography>
          <EditBalanceForm
            toggleModal={toggleModal}
            currentBalance={currentBalance}
            setCurrentBalance={setCurrentBalance}
            contractID={contractID}
            entityID={entityID}
            fundType={fundType}
            fundID={fundID}
            setFundID={setFundID}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

EditBalanceButton.propTypes = {
  className: PropTypes.string,
  currentBalance: PropTypes.number,
  setCurrentBalance: PropTypes.func,
  contractID: PropTypes.string,
  entityID: PropTypes.string,
  fundType: PropTypes.number,
  fundID: PropTypes.string,
  setFundID: PropTypes.func
};
