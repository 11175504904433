
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

  root: {
    //border:"1px solid red",
    height: "calc(100% - 0px)",
    padding: 8,
    position:"relative",
  },
  makeChoiceMessage: {
    textAlign:"center", 
    fontStyle:"italic", 
    marginTop:50, 
    width:"calc(100% - 15px)",  //hides animate.css' desire to push width on flip
    color: theme.palette.action.selected,
    animation: "fadeIn",
    animationDuration: "2s",
  },


  selector:{
    marginTop:20,

  },
  selectorSelect:{
    marginTop:0,
    marginBotom:0,
  },
  select: {
    padding: "10px 10px",
    backgroundColor:theme.palette.background.default,
  },

  queueListContainer:{
    overflowY:"auto",
    width:250, 
    height:"calc(100vh - 118px)", 
    //border:"2px solid green",

    borderRight:"1px solid silver",
    //make width break down
    '&::-webkit-scrollbar': {
      width: '0.5em'
    },    
    '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
    },
    '&.desktop-true': {
    },
    '&.desktop-false': {
      width:"100%", 
    },    
  },
  detailContainer: {
    position:"absolute", 
    top:8,
    left:270, 
    width:"calc(100% - 275px)", 
    height:"calc(100% - 15px)",
    //border:"1px solid orange",
    overflow: "auto",
    '&::-webkit-scrollbar': {
      width: '0.5em'
    },    
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
    }  ,  
    '&.hidelist-true': {
      left:10,
      width:"calc(100% - 15px)", 
    },
    '&.hidelist-false': {
      top:8,
      left:270, 
      width:"calc(100% - 275px)", 
    }   

  }
}));
