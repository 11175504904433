import React from "react";
import PropTypes from "prop-types";
import { Button, Zoom } from "@material-ui/core";
import useHubContext from "../../../../hooks/useHubContext";
import { useSelector, shallowEqual } from "react-redux";
import { FindEntity } from "../../../../state/slices/entities";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import CallCenterService from "../../../../services/CallCenterService";
import apiClient from "../../../../auth/apiClient";
import * as c from "../../../../constants/";
import moment from "moment";

const callCenterService = new CallCenterService(apiClient);

export default function SendCredentialButton({
  callID,
  credential,
  deviceID,
  ...props
}) {
  const { portalHub } = useHubContext();
  const currentUser = useSelector((state) => state.user, shallowEqual);
  const deviceName = useSelector(
    (state) => FindEntity(state.entities?.EntityList ?? [], deviceID)?.name,
    shallowEqual
  );
  const enqueueSnackbar = useEnqueueSnackbar();

  const handleSendCredential = async () => {
    try {
      await callCenterService.createAction(callID, {
        type: c.CALLCENTER_ACTIONS.OVERRIDE_CREDENTIAL,
        callCenterUserID: currentUser.UserID,
        actionTime: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ssZ"),
      });

      portalHub.invoke("CallCenter", {
        action: "OVERRIDE_CREDENTIAL",
        callDetails: {
          deviceID,
          callCenterUserID: currentUser.UserID,
          payload: credential,
        },
      });

      enqueueSnackbar(`Credential sent to device ${deviceName}`, {
        variant: "info",
        TransitionComponent: Zoom,
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "There was an issue sending the credential. Please try again.",
        {
          variant: "error",
          TransitionComponent: Zoom,
          tag: "ErrorSendingCredential",
        }
      );
    }
  };

  return (
    <Button {...props} onClick={handleSendCredential}>
      Send To Device
    </Button>
  );
}

SendCredentialButton.propTypes = {
  callID: PropTypes.string.isRequired,
  credential: PropTypes.object.isRequired,
  deviceID: PropTypes.string.isRequired,
};
