import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

  sectionHeader: {
    fontWeight: "bold",
    padding: "0.25em 0",
    color: theme.palette.grey.main
  },

  resultsContainer: {
    display: "flex",
    flexDirection: "column"
  }

}));