import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    flex: 1,
  },
  editDialog: {
    minWidth: "25vw",
  },
}));
