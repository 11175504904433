import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  
  buttonCreate: {
    marginLeft: 5
  },

  root: {
    marginTop: "1.5em",
  },
}));