import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { useStyles } from "./styles";
import clsx from "clsx";

const getDefaultSelectedIndex = (options, defaultSelected) => {
  return options.findIndex(
    (x) =>
      x.id.toLowerCase() === defaultSelected.toLowerCase() ||
      x.alt?.toLowerCase() === defaultSelected.toLowerCase()
  );
};

const SplitButton = ({
  className,
  options,
  align,
  onSubmit,
  defaultSelected,
  onChange,
  disabled,
}) => {
  const classes = useStyles();
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(
    defaultSelected ? getDefaultSelectedIndex(options, defaultSelected) : 0
  );
  const [open, setOpen] = useState(false);
  const [internalDefault, setInternalDefault] = useState(defaultSelected);

  const handleOptionClick = () => {
    onSubmit(options[selectedIndex]?.id);
  };

  const handleMenuItemClick = (e, index) => {
    setInternalDefault();
    setSelectedIndex(index);
    onChange(options[index]?.id);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid data-testid="split-btn" container direction="column" align={align}>
      <Grid item xs={12}>
        <ButtonGroup
          className={clsx(classes.rootBtn, className)}
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
          data-id="split-button"
          disabled={disabled}
        >
          <Button
            className={classes.rootBtn}
            onClick={handleOptionClick}
            data-id="primary-button"
            data-name={options[selectedIndex]?.name ?? "Unknown"}
            disabled={disabled}
          >
            {internalDefault
              ? options[getDefaultSelectedIndex(options, defaultSelected)]?.name
              : options[selectedIndex]?.name}
          </Button>
          <Button
            data-testid="split-dropdown-btn"
            color="primary"
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select option"
            aria-haspopup="menu"
            onClick={handleToggle}
            data-id="toggle-button"
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className={classes.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList data-id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        data-testid={option.id}
                        key={option.id}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        data-id={option.id}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

SplitButton.defaultProps = {
  options: [],
  align: "center",
  onSubmit: () => {},
  defaultSelected: "",
  onChange: () => {},
  disabled: false,
};

SplitButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      alt: PropTypes.string,
    })
  ),
  align: PropTypes.oneOf(["center", "left", "right"]),
  onSubmit: PropTypes.func,
  defaultSelected: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SplitButton;
