import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  credentialRow: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    overflowWrap: "break-word"
  },
  credentialChip: {
    minWidth: "120px",
  }
}));
