import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  rootBtn: {
    width: "100%",
  },
  popper: {
    zIndex: 100,
  },
}));
