import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import GroupedDropdown from "../../Dropdowns/Grouped";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useCancellationToken from "../../../hooks/useCancellationToken";
import CallCenterService from "../../../services/CallCenterService";
import apiClient from "../../../auth/apiClient";
import { FindNearestFacilityFromEntity } from "../../../state/slices/entities";
import { useSelector } from "react-redux";
import { Typography, Grid } from "@material-ui/core";
import SquareChip from "../../SquareChip";
import { useStyles } from "./Search.style";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard as accessHolderIcon, faCarSide as carIcon } from "@fortawesome/pro-regular-svg-icons";

const callCenterService = new CallCenterService(apiClient);

export const CATEGORIES = {
  AccessHolders: 0,
  ContractCredentials: 1,
  Tickets: 2,
};

const getCategory = (name) => {
  if (!name) return -1;
  if (name.toLowerCase().includes("access")) return CATEGORIES.AccessHolders;
  if (name.toLowerCase().includes("credential")) return CATEGORIES.ContractCredentials;
  if (name.toLowerCase().includes("ticket")) return CATEGORIES.Tickets;
};

export default function CallCenterSearch({ entityID, onSelect }) {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [searchedItems, setSearchedItems] = useState([]);
  const facility =
    useSelector((state) => FindNearestFacilityFromEntity(state.entities?.EntityList ?? [], entityID)) ?? {};

  const { execute: executeSearch, cancel: cancelSearch, inProgress } = useCancellationToken({
    func: async function({ entityid, searchTerm, cancelToken }) {
      const response = await callCenterService.search(entityid, searchTerm, cancelToken);

      let items;
      items = response.data ?? {};
      let flat = [];
      items.categories.forEach((category) => {
        const type = getCategory(category.name);
        if (category.children) {
          flat = [...flat, ...category.children.map((child) => handleCategoryMutation(type, child))];
        }
      });

      setSearchedItems(flat);
    },
    errHandleFunc: () => {
      enqueueSnackbar("Failed to retrieve search items", {
        variant: "error",
        tag: "FailedToRetrieveSearchItems",
      });
    },
  });

  const handleCategoryMutation = (type, obj) => {
    switch (type) {
      case CATEGORIES.AccessHolders:
        return mutateAccessHolder(obj);
      case CATEGORIES.ContractCredentials:
        return mutateContractCredential(obj);
      case CATEGORIES.Tickets:
        return mutateTicket(obj);
      default:
        return {};
    }
  };

  const mutateAccessHolder = (accessholder) => {
    accessholder.type = CATEGORIES.AccessHolders;
    accessholder.category = "Access Holders";
    let fullName;
    if (accessholder.lastname && accessholder.lastname !== "")
      fullName = accessholder.firstname.concat(" ", accessholder.lastname);
    else fullName = accessholder.firstname;

    accessholder.fullName = fullName;
    accessholder.label = fullName;
    accessholder.render = (
      <div>
        <Grid>
          <Typography>{fullName}</Typography>
        </Grid>
      </div>
    );

    return accessholder;
  };

  const mutateContractCredential = (credential) => {
    credential.type = CATEGORIES.ContractCredentials;
    credential.category = "Credentials";
    credential.label = credential.credentialreference;

    let fullName;
    if (credential.lastname && credential.lastname !== "")
      fullName = credential.firstname.concat(" ", credential.lastname);
    else fullName = credential.firstname;

    credential.render = (
      <div className={classes.credentialRow}>
        <Grid container>
          <Grid item xs={12} lg={5}>
            <Typography>{credential.credentialreference}</Typography>
          </Grid>
          <Grid item container xs={12} lg={4}>
            <Grid item xs={1} lg={2}>
              <FontAwesomeIcon icon={accessHolderIcon} />
            </Grid>
            <Grid item xs={11} lg={10}>
              <Typography>{fullName}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <SquareChip
              uppercase
              className={classes.credentialChip}
              mode="info"
              text={credential.credentialtype}
            />
          </Grid>
        </Grid>
      </div>
    );
    // Get the actual credential type name by updating the query on call center to join the credentialtypes table and grab the name
    // render the reference as text and put the type in a chip maybe?
    return credential;
  };

  const normalizeActivityType = (type) => {
    if (!type) {
      return "no activity";
    }
    switch (type?.toLowerCase()) {
      case "unset":
        return "no activity";
      case "created":
        return "active";
      case "enter":
        return "in";
      case "exit":
        return "out";
      default:
        return type;
    }
  };

  const mutateTicket = (ticket) => {
    ticket.type = CATEGORIES.Tickets;
    ticket.category = "Tickets";
    ticket.label = ticket.credentialreference;
    ticket.render = (
      <div className={classes.credentialRow}>
        <Grid container>
          <Grid item xs={12} lg={5}>
            <Typography>{ticket.credentialreference}</Typography>
          </Grid>
          <Grid item container xs={12} lg={4}>
            <Grid item xs={1} lg={2}>
              <FontAwesomeIcon icon={carIcon} />
            </Grid>
            <Grid item xs={11} lg={10}>
              <Typography>{ticket.cameraread ? ticket.cameraread : "Not Available"}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <SquareChip
              className={classes.credentialChip}
              uppercase
              mode={
                ticket.lastactivity?.toLowerCase() === "unset"
                  ? "warning"
                  : ticket.lastactivity?.toLowerCase() ?? "warning"
              }
              text={normalizeActivityType(ticket.lastactivity)}
            />
          </Grid>
        </Grid>
      </div>
    );

    return ticket;
  };

  const handleItemSelect = (item) => {
    onSelect(item);
  };

  const handleInputChange = (searchTerm) => {
    if (!searchTerm || searchTerm === "") {
      cancelSearch();
      setSearchedItems([]);
      return;
    }
    executeSearch({ entityid: facility.entityid, searchTerm });
  };

  return (
    <GroupedDropdown
      label={<span style={{ fontSize: "smaller" }}>Search for Credentials, Tickets, Accounts</span>}
      onInputChange={handleInputChange}
      loading={inProgress}
      options={searchedItems}
      groupBy="category"
      itemIdentifier="label"
      onSelect={handleItemSelect}
      delay={100}
      renderField="render"
      className={clsx(["call-center-search"])}
    />
  );
}

CallCenterSearch.defaultProps = {
  onSelect: () => {},
};

CallCenterSearch.propTypes = {
  entityID: PropTypes.string,
  onSelect: PropTypes.func,
};
