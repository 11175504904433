import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import apiClient from "../../../auth/apiClient";
import CallCenterService from "../../../services/CallCenterService";
import Image from "material-ui-image";
import { Box, Dialog, DialogContent, DialogActions, Button, DialogTitle } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useStyles } from "./EntityLPRTransactionDetails.styles";
import clsx from "clsx";

const callCenterService = new CallCenterService(apiClient);

const EntityLPRTransactionDetails = ({ lprInformation }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentLprPlateImage, setCurrentLprPlateImage] = useState(null);

  const lprId = lprInformation?.Id ?? 0;

  useEffect(() => {
    let isSubscribed = true;

    const fetchLPRImage = async (id) => {
      setLoading(true);
      try {
        const response = await callCenterService.getLicensePlateCaptureImage(id);
        var r = new FileReader();
        r.readAsDataURL(response.data);
        r.onload = () => {
          if (isSubscribed) {
            setCurrentLprPlateImage(r.result);
          }
        };
      } catch (err) {
        setCurrentLprPlateImage(null);
      } finally {
        setLoading(false);
      }
    };

  
    if (lprId > 0) {
      fetchLPRImage(lprId);
    } else {
      setCurrentLprPlateImage(null);
    }
    return () => (isSubscribed = false);
  }, [lprId]);

  return (
    <div
      className={clsx(classes.container, "transaction-info-license-plate")}
      data-platenumber={lprInformation?.LicensePlate}
      data-plateconfidence={lprInformation?.Confidence}
    >
      <Box className={classes.imageBox}>
        {loading && <Skeleton animation="wave" />}
        {currentLprPlateImage && (
          <>
            <Image
              className={classes.image}
              aspectRatio={3.0}
              alt="lpr-image"
              src={currentLprPlateImage}
              onClick={() => setOpenDialog(true)}
            />
            <Dialog maxWidth="xs" fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
              <DialogTitle>License Plate Image</DialogTitle>
              <DialogContent>
                <Image aspectRatio={3.0} alt="license-plate-dialog-view" src={currentLprPlateImage} />
              </DialogContent>
              <DialogActions>
                <Button aria-label="close-button" name="close" variant="contained" onClick={() => setOpenDialog(false)}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
      <Box className={classes.textBox}>
        {lprInformation?.LicensePlate && (
          <>
            <span className={classes.plateText}>{lprInformation?.LicensePlate}</span>
            <span>({lprInformation?.Confidence}% Confidence)</span>
          </>
        )}
      </Box>
    </div>
  );
};

EntityLPRTransactionDetails.defaultProps = { lprInformation: null };
EntityLPRTransactionDetails.propTypes = {
  lprInformation: PropTypes.object,
};

export default EntityLPRTransactionDetails;
