import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

  root: {
    position:"relative",
    
  },
  videoContainer:{
      border: "1px solid black",
      borderColor: theme.palette.action.disabled,
      position:"relative",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",    
      padding:0,
  },
 videoMirror: {
    transform: "rotateY(180deg)",
    '-webkit-transform': "rotateY(180deg)", /* Safari and Chrome */
    '-moz-transform':"rotateY(180deg)" /* Firefox */
},
  video:{
      '&[data-connected="true"]': {
        display:"inline-block",
      },
      '&[data-connected="false"]': {
        display:"none",
    }
  },
  videoWaiting: {
    color:theme.palette.action.hover, 
    '&[data-connected="true"]': {
        display:"none",
      },
      '&[data-connected="false"]': {
        display:"inline",
    },
  },
  videoActions:{
    marginTop:3,
    alignItems:"center",
    textAlign:"right",
    padding:0,
  },
  videoActionsName:{
    float:"left", 
    paddingLeft:6, 
    maxWidth:"50%",
    color: theme.palette.text.secondary,
  },

connecting: {
    position:"absolute",
    top:0,
    left:0,
},
notResponding:{
    color: theme.palette.error.main,
    fontSize: "1.4rem",
    paddingTop:4,
    marginRight:8,
    cursor:"help"
},
screenShotIcon: {
  cursor:"pointer",
  animation:"flipInY",
  animationDuration:"1s",
  color: theme.palette.primary.light,
},
screenShotWrapper: {
  backgroundColor:theme.palette.background.paper,
  paddingLeft:2,
  paddingRight:2,
  position:"absolute",
  display:"inline-block",
  top:7,
  right:7,
  padding:0,
  border:"1px solid silver"
}

}));
