import React, { useCallback,  useState } from "react"
import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";

import { useStyles } from "./AccessHolderResult.style";
import { AccessHolderDetail } from "./AccessHolderDetail.index";

export const AccessHolderResult = ({
  callID,
  entityID,
  accessHolder
}) => {
  const classes = useStyles();

  // TODO: let parent control expansion? Only allow one open at a time?
  // Works as is, each row can be expanded and closed independently
  const [detailExpanded, setDetailExpanded] = useState(false);

  const openDetail = () => {
    setDetailExpanded(true);
  }

  const closeDetail = () => {
    setDetailExpanded(false);
  }

  const handleKeyDown = useCallback((event) => {
    if (!detailExpanded && (event.key === "Enter" || event.key === " ")) {
      event.preventDefault();
      openDetail();
    }
  }, [detailExpanded])

  return detailExpanded 
    ? <AccessHolderDetail 
        callID={callID} 
        entityID={entityID} 
        accessHolderId={accessHolder.accessholderid} 
        close={closeDetail}/>
    : <div tabIndex={0}
        className={clsx(classes.resultRow, classes.clickableRow)}
        onKeyDown={handleKeyDown}
        onClick={openDetail}
        data-accessholder-name={accessHolder.lastname 
          ? accessHolder.firstname.concat(" ", accessHolder.lastname) 
          : accessHolder.firstname}>
        <Grid>
          <Typography>
            {accessHolder.lastname 
              ? accessHolder.firstname.concat(" ", accessHolder.lastname) 
              : accessHolder.firstname}
          </Typography>
        </Grid>
      </div>
};