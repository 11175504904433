import { lighten, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

  resultRow: {
    display: "flex",
    alignItems: "center",
    padding: "0.5em",
    margin: "0.25em 0"
  },

  clickableRow: {
    cursor: "pointer",
    "&:hover": {
      boxShadow: `0.5px 0.5px 0.5px 0.5px ${theme.palette.action.disabledBackground}`,
      backgroundColor: theme.palette.type === "light" 
        ? lighten(theme.palette.primary.light,.8) 
        : lighten(theme.palette.primary.light,.1),
      color: theme.palette.type === "light" 
        ? "inherit"
        : theme.palette.grey[900]
    },
    "&:focus": {
      boxShadow: `0.5px 0.5px 0.5px 0.5px ${theme.palette.action.disabledBackground}`,
      backgroundColor: theme.palette.type === "light" 
        ? lighten(theme.palette.primary.light,.8) 
        : lighten(theme.palette.primary.light,.1),
      color: theme.palette.type === "light" 
        ? "inherit"
        : theme.palette.grey[900]
    },
    transition: theme.transitions.create("box-shadow", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
  }
}));
