import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

  searchingContainer: {
    height: "2.25em",
    width: "96%",
    paddingLeft: "5px",
    paddingTop: "1em"
  },

  expandedDetailsContainer: {
    margin: "auto",
    marginBottom:"5px",
    padding: "1em",
    border: `solid 1px ${theme.palette.action.disabled}`,
    borderRadius: "5px",
    width: "96%" /* avoids horizontal scrollbar */
  },

  sendCredentialsBtnContainer: {
    display: "flex"
  },

  sendCredentialsBtn: {
    marginLeft: "auto"
  },
  collapseAH:{
    color: theme.palette.text.secondary,
    fontSize:"smaller",
  }
}));